import React from "react";
import useFooterData from "@staticQueries/FooterQuery";

import { Social } from "@molecules";
import { Container, Text, Icon } from "@atoms";
import { AppLink } from "@base";

const currentYear = new Date().getFullYear();

const hasWindow = typeof window !== "undefined";

const isSamePage = url => hasWindow && window.location.pathname.includes(url);

const scrollTo = target => {
  if (hasWindow) {
    document.getElementById(target).scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
};

const SubLink = ({ url, text }) => {
  const baseUrl = url?.split("#")[0];
  const anchor = url?.split("#")[1];
  const link = anchor && isSamePage(baseUrl) ? null : url;

  return (
    <li>
      <AppLink
        to={link}
        onClick={() => {
          // scroll again when clicking the same anchor link on the same page
          if (isSamePage(baseUrl)) {
            scrollTo(anchor);
          }
        }}
        className="text-white duration-300 hover:opacity-60"
      >
        <Text variant="xs">{text}</Text>
      </AppLink>
    </li>
  );
};

const Footer = () => {
  const { company, statement, headerLinks, privacy, phone, email, social } =
    useFooterData();

  // this function splits headerLinks into two arrays
  // one with sublinks and one without
  const groupedLinks = headerLinks.reduce(
    (acc, item) => {
      item.sublinks.length ? acc[0].push(item) : acc[1].push(item);
      return acc;
    },
    [[], []]
  );

  const contact = groupedLinks[1]?.at(-1)?.headLink?.url?.includes("contact")
    ? groupedLinks[1].pop()
    : null;

  return (
    <footer className="bg-midnight">
      <Container className="flex flex-col py-12 md:py-16">
        <div className="flex flex-wrap gap-8 md:flex-nowrap md:gap-16">
          {/* logo section */}
          <div className="flex w-3/4 flex-col items-start gap-4 sm:w-1/2 md:w-1/4 md:flex-shrink-0 lg:w-1/5">
            <AppLink to="/" className="block max-w-[6rem]">
              <Icon name="logoLight" className="w-full" />
            </AppLink>
            <Text
              variant="xxs"
              className="text-white"
            >{`&copy; ${currentYear} ${company.replace(/<[^>]+>/g, "")}`}</Text>
            {statement && (
              <Text variant="xxs" className="text-white">
                {statement}
              </Text>
            )}
            <Icon name="bCorp" className="h-auto w-8 text-white md:w-10" />
          </div>
          {/* nav */}
          <nav className="flex w-full flex-wrap gap-4 lg:flex-nowrap lg:justify-between">
            {/* links with sublinks */}
            {groupedLinks[0]?.map((group, i) => {
              return (
                <ul
                  className="mb-8 flex flex-[1_1_calc(50%-.5rem)] flex-col items-start space-y-4 text-left md:mb-12 lg:flex-1"
                  // eslint-disable-next-line react/no-array-index-key
                  key={group.uid || `parentLinkGroup--${i}`}
                >
                  <li>
                    <AppLink
                      to={group.headLink.url}
                      className="font-bold text-mint duration-300 hover:text-white"
                    >
                      <Text variant="h6sans">{group.headLink.text}</Text>
                    </AppLink>
                  </li>
                  {/* map sublinks within group */}
                  {group.sublinks.map(subgroup => {
                    if (subgroup.sublink.url) {
                      return (
                        <SubLink key={subgroup.uid} {...subgroup.sublink} />
                      );
                    }
                    return null;
                  })}
                </ul>
              );
            })}
            {/* links without sublinks */}
            <ul className="flex flex-[1_1_calc(50%-.5rem)] flex-col items-start space-y-4 text-left lg:flex-1">
              {groupedLinks[1].map((group, ii) => {
                return (
                  <li key={group.uid || `linkGroup--${ii}`}>
                    <AppLink
                      to={group.headLink.url}
                      className="font-bold text-mint duration-300 hover:text-white"
                    >
                      <Text variant="h6sans">{group.headLink.text}</Text>
                    </AppLink>
                    {group.headLink.url?.includes("contact") && (
                      <ul className="mt-4 flex flex-col space-y-4 break-all">
                        {privacy?.url && (
                          <SubLink url={privacy.url} text={privacy.text} />
                        )}
                        {phone?.url && (
                          <SubLink url={phone.url} text={phone.text} />
                        )}
                        {email?.url && (
                          <SubLink url={email.url} text={email.text} />
                        )}
                        <Social social={social} />
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
            {/* contact link */}
            {contact && (
              <ul className="flex flex-[1_1_calc(50%-.5rem)] flex-col items-start space-y-4 text-left lg:flex-1">
                <li key={contact.uid || `linkGroup--contact`}>
                  <AppLink
                    to={contact.headLink.url}
                    className="font-bold text-mint duration-300 hover:text-white"
                  >
                    <Text variant="h6sans">{contact.headLink.text}</Text>
                  </AppLink>
                  <ul className="mt-4 flex flex-col space-y-4 break-all">
                    {privacy?.url && (
                      <SubLink url={privacy.url} text={privacy.text} />
                    )}
                    {phone?.url && (
                      <SubLink url={phone.url} text={phone.text} />
                    )}
                    {email?.url && (
                      <SubLink url={email.url} text={email.text} />
                    )}
                    <Social social={social} />
                  </ul>
                </li>
              </ul>
            )}
          </nav>
        </div>
      </Container>
    </footer>
  );
};

Footer.defaultProps = {};

export default Footer;
